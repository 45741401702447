import axios, { AxiosPromise } from 'axios';
import { apiUrl } from '../../utils/ApplicationUtils';

export function presignZipUrl(apiToken: string | undefined, ifeAppVersionID: number): AxiosPromise<any> {
    const url = apiUrl + `/signed-ife-app-version/${ifeAppVersionID}`;

    const headers = {
        headers: {
            Authorization: 'Bearer ' + (apiToken ?? ''),
            'Content-Type': 'application/json'
        }
    };

    return axios.get(url, headers);
}
