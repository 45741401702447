import React, { useState } from 'react';
import { Tooltip } from '@airbus/components-react';
import { Download } from '@airbus/icons/react';
import i18n from 'i18next';
import classes from './CopyText.module.css';
import { selectToken } from '../../redux/app/features/credentialsSlice';
import { useSelector } from 'react-redux';
import { downloadZip } from './DownloadZipText.function';

type Props = {
    appVersionID: number;
};

export const DownloadZipText = ({ appVersionID }: Props) => {
    const token = useSelector(selectToken);
    const [tooltipText, setTooltipText] = useState<string>(i18n.t('applications.table.downloadSignZip'));

    const handleClick = () => {
        downloadZip(token, appVersionID);
        setTooltipText(i18n.t('applications.table.downnloadSignedSucces'));
    };
    return (
        <div className={classes.copy_text}>
            <Tooltip placement="top" label={tooltipText}>
                <Download
                    className={classes.vpn_key}
                    onClick={handleClick}
                    onMouseLeave={() => {
                        setTooltipText(i18n.t('applications.table.downloadSignZip'));
                    }}
                />
            </Tooltip>
        </div>
    );
};
