import i18n from 'i18next';
import { presignZipUrl } from '../../services/api/PresignedUrlZip';
import log from '../../logger';

export async function downloadZip(token: string | undefined, appVersionID: number) {
    const infosUrlpresign = await presignZipUrl(token, appVersionID);

    if (infosUrlpresign.data?.error) {
        log.error('Error in urlPresign', infosUrlpresign.data?.error);
        throw new Error(i18n.t('applications.table.error.presignURLError'));
    }
    const urlpresign = infosUrlpresign.data?.presignedUrl;
    const anchorElement = document.createElement('a');
    anchorElement.href = urlpresign;
    anchorElement.dispatchEvent(new MouseEvent('click'));

    // Free the URL after downloading (avoiding memory leak)
    URL.revokeObjectURL(urlpresign);
}
