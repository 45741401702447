import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import * as DateUtils from '../../../../utils/DateUtils';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Chip } from '@airbus/components-react';
import { Chevrondown, Chevronup, SubdirectoryArrowRight } from '@airbus/icons/react';

import { COMPONENT_STATUS } from '../../../../constants/AircraftDeploymentHistory';

import '../../../../i18n/config';
import { AppDisplayData, AppVersionData, RowDisplayData, ComponentData } from '../../types';
import styles from './DataTable.module.css';

type AppDisplayContent = {
    id: number;
    loading: string;
    upload: string;
    startDate: string;
    endDate: string;
    applications: number;
    sizeOfSync: string;
    status: string;
};

type AppVersionDisplayContent = {
    id: number;
    name: string;
    component: ComponentDisplayContent[];
};

type ComponentDisplayContent = {
    id: number;
    name: string;
    status?: React.ReactElement | string;
};

type AppRowContent = {
    mainRow: AppDisplayContent;
    subRows: AppVersionDisplayContent[];
};

type Props = {
    row: AppDisplayData;
};

function displayStatus(state: string | undefined): React.ReactElement | string {
    const statusColor = state === 'new' ? styles.color_blue : state === 'removed' ? styles.color_red : styles.color_orange;
    if (state !== undefined) {
        return <Chip className={`${styles.color_white} ${statusColor}`} label={COMPONENT_STATUS(state).LABEL} />;
    } else {
        return '';
    }
}

const toContent = (app: RowDisplayData): AppDisplayContent => ({
    id: app.id,
    loading: app.loading,
    upload: app.upload,
    startDate: DateUtils.prettyDate(app.startDate),
    endDate: DateUtils.prettyDate(app.endtDate),
    applications: app.applications,
    sizeOfSync: app.sizeOfSync,
    status: app.status
});

const toComponent = (component: ComponentData): ComponentDisplayContent => ({
    id: component.id,
    name: component.name,
    status: displayStatus(component.status)
});

const toSubContent = (appVersion: AppVersionData): AppVersionDisplayContent => ({
    id: appVersion.id,
    name: appVersion.name,
    component: appVersion.component.map(toComponent)
});

const appDataToRow = (app: AppDisplayData): AppRowContent => ({
    mainRow: toContent(app.mainApp),
    subRows: app.appVersion.map(toSubContent)
});

const DataRow = ({ row }: Props) => {
    const [open, setOpen] = useState<boolean>(false);

    row.appVersion.sort((a: AppVersionData, b: AppVersionData) => {
        return (b.createdAt ? b.createdAt.getTime() : 0) - (a.createdAt ? a.createdAt.getTime() : 0);
    });

    const appContent: AppRowContent = appDataToRow(row);

    return (
        <>
            <tr style={open ? { height: '2em' } : { height: '0.5em' }} />
            <TableRow className={`${styles.data_row} ${open ? styles.data_row_open : ''}`}>
                <TableCell className={open ? styles.data_cell_bold : styles.font_weight_bold}>{appContent.mainRow.loading}</TableCell>
                <TableCell className={open ? styles.data_cell_bold : styles.font_weight_bold}>{appContent.mainRow.upload}</TableCell>
                <TableCell className={open ? styles.color_white : ''}>{appContent.mainRow.startDate}</TableCell>
                <TableCell className={open ? styles.color_white : ''}>{appContent.mainRow.endDate}</TableCell>
                <TableCell className={open ? styles.color_white : ''}>{appContent.mainRow.applications}</TableCell>
                <TableCell className={open ? styles.color_white : ''}>{appContent.mainRow.sizeOfSync}</TableCell>
                <TableCell className={open ? styles.color_white : ''}>{appContent.mainRow.status}</TableCell>
                <TableCell>
                    <IconButton
                        style={open ? { color: 'white' } : { color: '#063b9e' }}
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}>
                        {open ? <Chevronup /> : <Chevrondown />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow key={appContent.mainRow.id} style={open ? { border: '3px solid #00205b' } : {}}>
                <TableCell key={appContent.mainRow.id} style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Table>
                                <TableBody>
                                    {appContent.subRows.length > 0 &&
                                        appContent.subRows.map((appVersion: AppVersionDisplayContent, index: number) => (
                                            <TableRow key={index} className={styles.table_row}>
                                                <TableCell className={styles.sub_row_app}>
                                                    <SubdirectoryArrowRight className={styles.subdirectory_arrow_right} />
                                                    {appVersion.name || '-'}
                                                </TableCell>
                                                <TableCell className={styles.sub_row_sub_type}>
                                                    <div className={styles.app_version_container}>
                                                        {appVersion.component.map((comp) => (
                                                            <div className={styles.app_version} key={comp.id}>
                                                                <span className={styles.component_text}>{comp.name}</span>
                                                                <span>{comp.status}</span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};
export default DataRow;
